import React from 'react'
import { graphql, Link } from 'gatsby'
import { MdLocationOn } from 'react-icons/md'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/Hero/Hero'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <Hero
      image={data.hero.childImageSharp.fluid}
      styleClass="hero"
      title="Welkom bij Logopediepraktijk Mireille Peters"
    >
      <div className="locations">
        {data.locaties.edges.map(locatie => (
          <Link
            to={`/locaties/${locatie.node.locatie.toLowerCase()}/`}
            className="location"
            key={locatie.node.id}
          >
            <MdLocationOn className="icon icon--location" />
            {locatie.node.locatie}
          </Link>
        ))}
      </div>
    </Hero>
  </Layout>
)

export const query = graphql`
  {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    locaties: allContentfulLocaties(sort: { fields: [locatie], order: DESC }) {
      edges {
        node {
          id
          locatie
        }
      }
    }
  }
`
export default IndexPage
