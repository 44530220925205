import React from 'react'
import BackgroundImage from 'gatsby-background-image'
export default function Hero({ image, styleClass, title, children }) {
  return (
    <BackgroundImage className={styleClass} fluid={image}>
      <h1 className="title text-white text-uppercase text-center display-5">
        {title}
      </h1>
      {children}
    </BackgroundImage>
  )
}

Hero.defaultProps = {
  title: 'default title',
  styleClass: 'hero',
}
